.contact-container {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    gap: 1.5rem;
}

.contact-title {
    font-size: 2rem;
    text-align: center;
}

.contact-text {
    width: 80%;
    font-size: 1.4rem;
    text-align: center;
    word-wrap: break-word;
}

.logo-container {
    width: 60%;
    height: auto;
    overflow: hidden;
}

.contact-logo {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.email-link {
    text-decoration: none;
    font-weight: bold;
    color: #244C46 !important;
}

.email-link:link {
    text-decoration: none;
    color: #244C46 !important;
}

.email-link:visited {
    text-decoration: none;
    color: #244C46 !important;
}

.email-link:active {
    text-decoration: none;
    color: #244C46 !important;
}

@media only screen and (min-width: 750px) {
    .contact-container {
        margin: 6rem auto;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    .contact-title {
        width: 100%;
        font-size: 2rem;
    }

    .logo-container {
        width: 30%;
    }

    .contact-text {
        width: 40%;
        font-size: 1.2rem;
    }
}

@media only screen and (min-width: 1000px) {
    .contact-container {
        margin: 7rem auto;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    .contact-title {
        width: 100%;
        font-size: 3rem;
    }

    .logo-container {
        width: 30%;
    }

    .contact-text {
        width: 40%;
        font-size: 1.8rem;
    }
}