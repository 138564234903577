.about-me-container  {
    width: 90%;
    margin: 2rem auto;
    padding-bottom: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    gap: 2rem;
}

.about-title {
    font-size: 2rem;
    text-align: center;
}

.photo-container {
    width: 50%;
    height: auto;
}

.profile-photo {
    width: 100%;
    height: auto;
    border-radius: 50%;
}

.blurb {
    width: 70%;
    font-size: 1.2rem;
    text-align: center;
}

@media only screen and (min-width: 800px) {
    .about-me-container {
        margin: 5rem auto;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    .about-title {
        width: 100%;
        margin-right: 7rem;
        font-size: 2rem;
        text-align: right;
    }

    .photo-container {
        width: 30%;
    }

    .blurb {
        width: 40%;
        font-size: 1.2rem;
    }
}

@media only screen and (min-width: 1000px) {
    .about-me-container {
        margin: 7rem auto;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    .about-title {
    width: 100%;
    margin-right: 18rem;
    font-size: 2rem;
    text-align: right;
    }

    .photo-container {
        width: 30%;
    }

    .blurb {
        width: 40%;
        font-size: 1.8rem;
    }
}
