.nav-container {
    width: 100vw;
    margin: 0;
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: #FFFFFF;
    color: #3B816F;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    left: 0;
}

.main-navigation {
    width: 100%;
    display: flex;
}

.menu-buttons {
    width: 80%;
    margin: 0 auto;
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: space-between;
}

.menu-item {
    color: #3B816F;
    text-decoration: none;
}

.menu-item:hover {
    color: #A16812;
}